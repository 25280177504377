<template>
  <v-card>
    <v-card-title>
      <v-icon left>{{icons.mdiAccountMultiplePlus}}</v-icon>
      <span class="primary--text-kh">{{$t('menu.crimeTypeCreation')}}</span>
      <v-spacer></v-spacer>
      <v-btn class="primary mt-2" medium rounded @click.stop="dialog = true">
        <v-icon left>{{icons.mdiPlusThick}}</v-icon> {{$t('button.newCrimeType')}}
      </v-btn>
    </v-card-title>
    <v-dialog v-model="dialog" persistent max-width="800">
      <v-card>
        <v-toolbar>
          <v-toolbar-title>
            <span class="primary--text-kh">{{ dialogTitle }}</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text fab dark large color="primary" @click="dialog = false">
            <v-icon>{{icons.mdiClose}}</v-icon>
          </v-btn>
        </v-toolbar>
        <br />
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field :label="$t('text.crimeTypeNameKh')" :append-icon="icons.mdiAccount" dense outlined v-model="caseCrimeType.crimeTypeNameKh" :rules="rule.requiredField()" hide-details></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field :label="$t('text.crimeTypeNameEn')" :append-icon="icons.mdiAccount" v-model="caseCrimeType.crimeTypeNameEn" :rules="rule.requiredField()" dense outlined hide-details></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-select :items="severityList" v-model="caseCrimeType.severity" item-value="value" label="ទំងន់បទល្មើស" outlined dense hide-details>
                  <template slot="selection" slot-scope="data">
                    {{data.item.text}}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{data.item.text}}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field label="មាត្រា" :append-icon="icons.mdiAccount" dense outlined v-model="caseCrimeType.article" hide-details></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-select :items="lawList" v-model="caseCrimeType.law" return-object label="ច្បាប់" outlined dense hide-details>
                  <template slot="selection" slot-scope="data">
                    {{data.item.lawNameKh}}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{data.item.lawNameKh}}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary" @click="save">
            <v-icon dark left>{{icons.mdiContentSave}}</v-icon> {{$t('button.save')}}
          </v-btn>
          <v-btn class="secondary" @click="dialog = false">
            <v-icon left>{{icons.mdiCancel}}</v-icon> {{$t('button.cancel')}}
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-card-text>
      <v-data-table :headers="headers" :items="caseCrimeTypeList" :search="search">
        <template v-slot:[`item.severity`]="{ item }">
          <span v-if="item.severity === 1">លហុ</span>
          <span v-else-if="item.severity === 2">មជ្ឈឹម</span>
          <span v-else-if="item.severity === 3">មជ្ឈឹម</span>
          <span v-else></span>
        </template>
        <template v-slot:[`item.law`]="{ item }">
          <span v-if="item.law != null">{{item.law.lawNameKh}}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium class="mr-2" @click="editItem(item)">
            {{icons.mdiPencil}}
          </v-icon>
          <v-icon medium @click="deleteItem(item)">
            {{icons.mdiDelete}}
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiPencil,
  mdiDelete,
  mdiContentSave,
  mdiCancel,
  mdiMagnify,
  mdiPlusThick,
  mdiClose
} from '@mdi/js'
import SystemService from '@/services/service.system'
import Rule from '@/plugins/rules.js'

export default {
  name: 'case-letter-type',
  data() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
        mdiContentSave,
        mdiCancel,
        mdiMagnify,
        mdiPlusThick,
        mdiClose
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'ឈ្មោះបទល្មើស', value: 'crimeTypeNameKh' },
        { text: 'Crime Name', value: 'crimeTypeNameEn' },
        { text: 'ទំងន់ទោស', value: 'severity' },
        { text: 'ប្រភេទច្បាប់', value: 'law' },
        { text: 'ACTION', value: 'actions', sortable: false }
      ],
      severityList: [
        { text: 'លហុ', value: 1 },
        { text: 'មជ្ឈឹម', value: 2 },
        { text: 'ឧក្រិដ្ឋ', value: 3 }
      ],
      lawList: [],
      rule: [],
      caseCrimeType: {},
      caseCrimeTypeList: [],
      dialog: false,
      dialogDelete: false,
      search: '',
      resultMessage: '',
      editedIndex: -1
    }
  },
  created() {
    this.rule = Rule
  },
  computed: {
    dialogTitle() {
      return this.editedIndex === -1
        ? this.$t('caption.newCrimeType')
        : this.$t('caption.editCrimeType')
    },
    selectedItems: {
      get() {
        return this.value
      },
      set(item) {
        this.chosenItems.push(item)
        this.$emit('input', item)
      }
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    }
  },
  mounted() {
    SystemService.getCaseCrimeTypeAll().then(response => {
      this.caseCrimeTypeList = response.data
    })

    SystemService.getLawAll().then(response => {
      this.lawList = response.data
    })
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.caseCrimeTypeList.indexOf(item)
      this.caseCrimeType = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem(item) {
      this.$confirm({
        message: 'Do you want to delete ' + item.evidenceTypeNameKh + '?',
        button: {
          no: 'No',
          yes: 'Yes'
        },
        callback: confirm => {
          if (confirm) {
            SystemService.deleteCrimeTypeById(item.id).then(
              () => {
                const index = this.caseCrimeTypeList.indexOf(item)
                this.caseCrimeTypeList.splice(index, 1)
                this.$notify({
                  group: 'message',
                  title: 'Notification',
                  type: 'success',
                  text: 'Position has been deleted'
                })
              },
              error => {
                this.$notify({
                  group: 'message',
                  title: 'Notification',
                  type: 'error',
                  text: error.response ? error.response.data : 'An error occurs'
                })
              }
            )
          }
        }
      })
    },
    save() {
      if (!this.$refs.form.validate()) return
      SystemService.insertCaseCrimeType(this.caseCrimeType).then(
        response => {
          if (response.data) {
            if (this.editedIndex == -1) {
              this.$notify({
                group: 'message',
                title: 'Notification',
                type: 'success',
                text: this.$t('message.addedCaseLetterType')
              })

              this.caseCrimeTypeList.push(response.data)
            } else {
              this.$notify({
                group: 'message',
                title: 'Notification',
                type: 'success',
                text: this.$t('message.updatedCaseLetterType')
              })
              Object.assign(
                this.caseCrimeTypeList[this.editedIndex],
                response.data
              )
            }
            this.dialog = false
          }
        },
        error => {
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'error',
            text: error.response ? error.response.data : 'An error occurs'
          })
        }
      )
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.caseCrimeType = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    }
  }
}
</script>
